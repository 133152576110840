<template>
  <b-row>
    <b-col md="12" sm="12" lg="12">
        <b-form-group
          label-cols-sm="4"
          :label-for="data.field_name"
        >
          <template v-slot:label>
            {{ $i18n.locale === 'bn' ? data.label_bn : data.label }}
          </template>
          <div v-if="data.value">
            <div class="custom-data">
              <span class="text-bold" style="font-weight: 600;"> : {{dropDownCheck()}}</span>
            </div>
          </div>
          <p v-else class="text-bold" style="font-weight: 600;"> : ..................</p>
        </b-form-group>
    </b-col>
    <b-col md="12" sm="12" lg="12">
      <!-- <pre>{{productList}}</pre> -->
      <b-form-group
        label-cols-sm="4"
        :label-for="productList.field_name"
      >
        <template v-slot:label>
          {{ $i18n.locale === 'bn' ? productList.label_bn : productList.label }}
        </template>
        <div v-if="productList.value">
          <div class="custom-data">
            <span class="text-bold" style="font-weight: 600;"> : {{dropDownCheckProduct()}} </span>
          </div>
        </div>
        <p v-else class="text-bold" style="font-weight: 600;"> : ..................</p>
      </b-form-group>
    </b-col>
  </b-row>
</template>
<script>
import CommonLrcpn from '@/mixins/common-lrcpn'
export default {
    mixins: [CommonLrcpn],
    props: ['data', 'productList', 'isAdmin'],
    components: {
    },
    data () {
      return {
        drpList: []
      }
    },
    computed: {
      agricultureProductTypeList: function () {
        if (this.isAdmin) {
          return this.$store.state.licenseRegistration.commonObj.agricultureProductTypeList
        } else {
          return this.$store.state.ExternalLrcpn.lrcpnObj.agricultureProductTypeList
        }
      },
      agricultureProductList: function () {
        if (this.isAdmin) {
          return this.$store.state.licenseRegistration.commonObj.agricultureProductList
        } else {
          return this.$store.state.ExternalLrcpn.lrcpnObj.agricultureProductList
        }
      }
    },
    created () {
      if (this.data.value) {
        const datasVal = this.data.value
        this.data.value = JSON.parse(this.data.value)
        if (!Array.isArray(this.data.value)) {
          this.data.value = datasVal
        }
      }
      if (this.productList.value) {
        const datasVasl = this.productList.value
        this.productList.value = JSON.parse(this.productList.value)
        if (!Array.isArray(this.productList.value)) {
          this.productList.value = datasVasl
        }
      }
    },
    methods: {
      dropDownCheck () {
        const dataVal = []
        if (this.data.value) {
          this.data.value.forEach((item, key) => {
            const dataFind = this.agricultureProductTypeList.find(dt => dt.value === item)
            if (this.$i18n.locale === 'bn') {
              dataVal.push(dataFind.text_bn)
            } else {
              dataVal.push(dataFind.text_en)
            }
          })
        }
        return dataVal.toString()
      },
      dropDownCheckProduct () {
        const dataVal = []
        if (this.productList.value) {
          this.productList.value.forEach((item, key) => {
            const dataFind = this.agricultureProductList.find(dt => dt.value === item)
            if (this.$i18n.locale === 'bn') {
              dataVal.push(dataFind.text_bn)
            } else {
              dataVal.push(dataFind.text_en)
            }
          })
        }
        return dataVal.toString()
      }
    }
}
</script>
