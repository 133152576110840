<template>
  <b-container fluid>
    <div class="col-sm-12 col-md-12 col-lg-12 text-center">
      <!-- <b-button variant="primary" @click="pdfExport" class="mr-2 mb-2 btn-sm float-right">
            <i class="fa fa-print"></i> {{  $t('globalTrans.print') }}
    </b-button> -->
      <h4 class="">{{ $i18n.locale === 'bn' ? 'ফসলের বীজ আমদানির আবেদন ফরম (বোরো হাইব্রিড)' : 'Application Form for Import of Crop Seed (Boro Hybrid)' }}</h4>
    </div>
    <hr/>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h6 class="">{{ $i18n.locale === 'bn' ? 'বরাবর' : 'To' }}</h6>
        <h6 class="">{{ $i18n.locale === 'bn' ? 'সচিব,জাতীয় বীজ বোর্ড' : 'Secretary, National Seed Board' }}</h6>
        <h6 class="">{{ $i18n.locale === 'bn' ? 'ও মহাপরিচালক, বীজ অনুবিভাগ' : 'and Director General, Seed Wing' }}</h6>
        <h6 class="">{{ $i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়।' : 'Ministry of Agriculture' }}</h6>
        <br/>
        <h6 class="">{{ $i18n.locale === 'bn' ? 'দৃষ্টি আকর্ষণঃ প্রধান বীজতত্ত্ববিদ ও সদস্য সচিব।' : 'Attention: Chief Botanist and Member Secretary' }}</h6>
        <br/>
        <h6 class="">{{ $i18n.locale === 'bn' ? 'বিষয় : বোরো হাইব্রিড ফসলের বীজ আমদানির আবেদন।' : 'Subject: Application for Import of Boro Hybrid Crop Seeds' }}</h6>
        <br/>
        <h6 class="">{{ $i18n.locale === 'bn' ? 'জনাব,' : 'Dear Sir,' }}</h6>
        <h6 class="">{{ $i18n.locale === 'bn' ? 'সবিনয় নিবেদন এই যে, আমি নিম্নস্বাক্ষরকারী উপর্যুক্ত বিষয়ে প্রয়োজনীয় তথ্যাদি আপনার সদয় বিবেচনার জন্য উপস্থাপন করলাম।' : 'With due respect, I, the undersigned, am submitting the necessary information on the aforementioned subject for your kind consideration.' }}</h6>
        <br/>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 text-left">
        <table style="width: 80%">
          <tr class="m-1">
            <td style="width: 30%">{{ $t('globalTrans.applicant_name') }}</td>
            <td style="width: 5%">:</td>
            <td>{{ $i18n.locale === 'bn' ? badcStaticData[0].applicant____3611 : badcStaticData[0].applicant____6805 }}</td>
          </tr>
          <tr>
            <td style="width: 30%">{{ $t('globalTrans.app_designation') }}</td>
            <td style="width: 5%">:</td>
            <td>{{ $i18n.locale === 'bn' ? badcStaticData[0].applicant__4223 : badcStaticData[0].applicant_______4296 }}</td>
          </tr>
          <tr>
            <td style="width: 30%">{{ $t('globalTrans.inst_name') }}</td>
            <td style="width: 5%">:</td>
            <td>{{ $i18n.locale === 'bn' ? badcStaticData[0].name_and_a____7450 : badcStaticData[0].name_and_a____8831 }}</td>
          </tr>
          <tr>
            <td style="width: 30%">{{ $t('globalTrans.address') }}</td>
            <td style="width: 5%">:</td>
            <td>{{ $i18n.locale === 'bn' ? badcStaticData[0].addressbn_3295 : badcStaticData[0].addressen_5439 }}</td>
          </tr>
          <tr>
            <td style="width: 30%">{{$t('org_pro_division.division')}}</td>
            <td style="width: 5%">:</td>
            <td> {{ getDivisionName(badcStaticData[0].division_i_4432) }} </td>
          </tr>
          <tr>
            <td style="width: 30%">{{$t('org_pro_district.district')}}</td>
            <td style="width: 5%">:</td>
            <td> {{ getDistrictName(badcStaticData[0].district_id_3065) }} </td>
          </tr>
          <tr>
            <td style="width: 30%">{{$t('org_pro_upazilla.upazilla')}}</td>
            <td style="width: 5%">:</td>
            <td> {{ getUpazilaName(badcStaticData[0].upazila_id_4543) }} </td>
          </tr>
          <tr>
            <td style="width: 30%">{{$t('org_pro.com_reg')}}</td>
            <td style="width: 5%">:</td>
            <td> {{ badcStaticData[0].company_re___5184 }} </td>
          </tr>
          <tr>
            <td style="width: 30%">{{$t('org_pro.mobile_no')}}</td>
            <td style="width: 5%">:</td>
            <td> {{ $n(0) + $n(badcStaticData[0].mobile_no__1597 , { useGrouping: false }) }} </td>
          </tr>
          <tr>
            <td style="width: 30%">{{$t('org_pro.email')}}</td>
            <td style="width: 5%">:</td>
            <td> {{ badcStaticData[0].email___3403 }} </td>
          </tr>
          <tr>
            <td style="width: 30%">{{$t('org_pro.seed_import')}}</td>
            <td style="width: 5%">:</td>
            <td> {{ badcStaticData[0].purpose_of_impo_5078 }} </td>
          </tr>
          <tr>
            <td style="width: 30%">{{$t('org_pro.import_crop')}}</td>
            <td style="width: 5%">:</td>
            <td> {{ $i18n.locale === 'bn' ? badcStaticData[0].imported_c_4085 : badcStaticData[0].seed_deale_9723 }} </td>
          </tr>
        </table>
        <br/>
        <br/>
        <div class="col-sm-12 col-md-12 col-lg-12 text-left">
          <h5 class="">{{ $t('org_pro.table_one') }}</h5>
          <hr/>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 text-left">
            <b-table-simple striped bordered small class="mt-2">
                <b-tr>
                    <b-th> {{ $t('globalTrans.sl_no') }}</b-th>
                    <b-th> {{ $t('org_pro.crop_variant') }}</b-th>
                    <b-th> {{ $t('org_pro.meet_num') }}</b-th>
                    <b-th> {{ $t('org_pro.nsb_year') }}</b-th>
                    <b-th> {{ $t('org_pro.variant_cycle') }}</b-th>
                    <b-th> {{ $t('org_pro.hector_crop') }}</b-th>
                    <b-th> {{ $t('org_pro.approve_quant') }}</b-th>
                    <b-th> {{ $t('org_pro.true_quant') }}</b-th>
                    <b-th> {{ $t('org_pro.local_pro') }}</b-th>
                    <b-th> {{ $t('org_pro.pro_quant') }}</b-th>
                    <b-th> {{ $t('org_pro.unsold_quant') }}</b-th>
                    <b-th> {{ $t('globalTrans.remarks') }}</b-th>
                </b-tr>
                <b-tr v-for="(dataIt, index) in JSON.parse(badcStaticData[0].add_more)" :key="index">
                  <b-td>{{ $n(index + 1) }}</b-td>
                  <b-td>{{ dataIt['Crop Variety Name '] }}</b-td>
                  <b-td>{{ dataIt['Number of Meetings'] }}</b-td>
                  <b-td>{{ dataIt['NSB Registration Year'] }}</b-td>
                  <b-td>{{ dataIt['Crop Lifetime (Day)'] }}</b-td>
                  <b-td>{{ dataIt['Yield per hectare (M. Ton)'] }}</b-td>
                  <b-td>{{ dataIt['Permitted Import Amount (Metric Ton)'] }}</b-td>
                  <b-td>{{ dataIt['Actual Import Amount (Metric Ton)'] }}</b-td>
                  <b-td>{{ dataIt['Local Production (Metric Ton)'] }}</b-td>
                  <b-td>{{ dataIt['Sell Amount (Metric Ton)'] }}</b-td>
                  <b-td>{{ dataIt['Amount of unsold seed (metric tons)'] }}</b-td>
                  <b-td>{{ dataIt['Comments'] }}</b-td>
                </b-tr>
              </b-table-simple>
          </div>
        </div>
        <br/>
        <br/>
        <div class="col-sm-12 col-md-12 col-lg-12 text-left">
          <h5 class="">{{ $t('org_pro.table_two') }}</h5>
          <hr/>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 text-left">
            <b-table-simple striped bordered small class="mt-2">
                <b-tr>
                    <b-th> {{ $t('globalTrans.sl_no') }}</b-th>
                    <b-th> {{ $t('org_pro.hybrid_variant') }}</b-th>
                    <b-th> {{ $t('org_pro.nsb_year') }}</b-th>
                    <b-th> {{ $t('org_pro.meet_num') }}</b-th>
                    <b-th> {{ $t('org_pro.f_one') }}</b-th>
                    <b-th> {{ $t('org_pro.a_line') }}</b-th>
                    <b-th> {{ $t('org_pro.r_line') }}</b-th>
                    <b-th> {{ $t('org_pro.pro_target') }}</b-th>
                    <b-th> {{ $t('globalTrans.remarks') }}</b-th>
                    <b-th> {{ $t('org_pro.pro_area') }}</b-th>
                </b-tr>
                <b-tr v-for="(dataIt, index) in JSON.parse(badcStaticData[0].add_more_1575)" :key="index">
                  <b-td>{{ $n(index + 1) }}</b-td>
                  <b-td>{{ dataIt['Hybrid Crop Variety'] }}</b-td>
                  <b-td>{{ dataIt['Date of NSB meeting'] }}</b-td>
                  <b-td>{{ dataIt['Number of meetings'] }}</b-td>
                  <b-td>{{ dataIt['F-1 (MT)'] }}</b-td>
                  <b-td>{{ dataIt['\'A\' Line (kg)'] }}</b-td>
                  <b-td>{{ dataIt['\'R\' Line (kg)'] }}</b-td>
                  <b-td>{{ dataIt['Land Target for Seed Production (Acres)'] }}</b-td>
                  <b-td>{{ dataIt['Comment'] }}</b-td>
                  <b-td>{{ dataIt['Cultivated Area / Name of Upazila'] }}</b-td>
                </b-tr>
              </b-table-simple>
          </div>
        </div>
        <br/>
        <br/>
        <div class="col-sm-12 col-md-12 col-lg-12 text-left">
          <h5 class="">{{ $t('org_pro.table_three') }}</h5>
          <hr/>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 text-left">
            <b-table-simple striped bordered small class="mt-2">
                <b-tr>
                    <b-th> {{ $t('globalTrans.sl_no') }}</b-th>
                    <b-th> {{ $t('org_pro.variety_name') }}</b-th>
                    <b-th> {{ $t('org_pro.nsb_year') }}</b-th>
                    <b-th> {{ $t('org_pro.meet_num') }}</b-th>
                    <b-th> {{ $t('org_pro.variety_des') }}</b-th>
                    <b-th> {{ $t('org_pro.f_one') }}</b-th>
                    <b-th> {{ $t('org_pro.a_line') }}</b-th>
                    <b-th> {{ $t('org_pro.r_line') }}</b-th>
                    <b-th> {{ $t('org_pro.crop_quant') }}</b-th>
                    <b-th> {{ $t('globalTrans.remarks') }}</b-th>
                    <b-th> {{ $t('org_pro.pro_area') }}</b-th>
                </b-tr>
                <b-tr v-for="(dataIt, index) in JSON.parse(badcStaticData[0].add_more_2087)" :key="index">
                  <!-- <pre>{{ dataIt }}</pre> -->
                  <b-td>{{ $n(index + 1) }}</b-td>
                  <b-td>{{ dataIt['Crop Variety Name'] }}</b-td>
                  <b-td>{{ dataIt['NSB Registration Year'] }}</b-td>
                  <b-td>{{ dataIt['Number of Meetings'] }}</b-td>
                  <b-td>{{ dataIt['Variety Details'] }}</b-td>
                  <b-td>{{ dataIt['F-1 (Metric Ton)'] }}</b-td>
                  <b-td>{{ dataIt['A line (kg)'] }}</b-td>
                  <b-td>{{ dataIt['R line (kg)'] }}</b-td>
                  <b-td>{{ dataIt['Amount of land (acres)'] }}</b-td>
                  <b-td>{{ dataIt['Comments'] }}</b-td>
                  <b-td>{{ dataIt['Name of Cultivated Area / Upazila'] }}</b-td>
                </b-tr>
              </b-table-simple>
          </div>
        </div>
        <br>
     </div>
   </div>
    <br/>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h6 class="">{{ $t('org_pro.end_line') }}</h6>
      </div>
    </div>
  </b-container>
</template>
<script>
import { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import ExportPdf from './export_pdf'

export default {
  props: ['badcStaticData', 'officeData', 'isAdmin'],
  data () {
    return {
      loading: false,
      badcNew: [],
      lrcpanBaseUrl: licenseRegistrationServiceBaseUrl,
      tubeDrop:
      [
        { text: this.$i18n.locale === 'bn' ? 'গভীর নলকূপ' : 'Deep Tubewell', value: 0 },
        { text: this.$i18n.locale === 'bn' ? 'অগভীর নলকূপ' : 'Shallow Tubewell', value: 1 }
      ],
      purposeList:
      [
        { value: 0, text: this.$i18n.locale === 'bn' ? 'গবেষণা' : 'Deep Tubewell' },
        { value: 1, text: this.$i18n.locale === 'bn' ? 'বাণিজ্যিক' : 'Deep Tubewell' },
        { value: 2, text: this.$i18n.locale === 'bn' ? 'উপযোগিতা যাচাই' : 'Deep Tubewell' }
      ],
      tubeCap:
      [
        { text: this.$i18n.locale === 'bn' ? '২.০ কিউসেক' : '2.0 Cusec', value: 0 },
        { text: this.$i18n.locale === 'bn' ? '১.৫ কিউসেক' : '1.5 Cusec', value: 1 },
        { text: this.$i18n.locale === 'bn' ? '১.০ কিউসেক' : '1.0 Cusec', value: 2 },
        { text: this.$i18n.locale === 'bn' ? '০.৫ কিউসেক' : '0.5 Cusec', value: 3 },
        { text: this.$i18n.locale === 'bn' ? 'নাই' : 'N/A', value: 4 }
      ]
    }
  },
  created () {
  },
  computed: {
  },
  methods: {
    tubeDropSet (fieldVals) {
      const dataVal = []
      const flVal = JSON.parse(fieldVals)
      if (flVal) {
        flVal.forEach((item, key) => {
          const dataFind = this.tubeDrop.find(dt => dt.value === parseInt(item))
          if (this.$i18n.locale === 'bn') {
            dataVal.push(dataFind.text)
          } else {
            dataVal.push(dataFind.text)
          }
        })
      }
      return dataVal.toString()
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    getColumnNameOt (list, groupId) {
      const obj = list.find(item => parseInt(item.value) === parseInt(groupId))
      if (typeof obj !== 'undefined') {
        return obj.text
      } else {
          return ''
      }
    },
    addMoreDataReturn (index, value) {
      let newDD = ''
      const allJsonData = JSON.parse(this.badcStaticData[0].add_more_3656)[index]
      Object.keys(allJsonData).forEach(key => {
        if (key === 'Nearest  Tubewell  Capacity (Cusec)') {
          const checkData = allJsonData['Nearest  Tubewell  Capacity (Cusec)']
          newDD = checkData
        }
      })
      return newDD
    },
    getIsFile (value) {
        if (value !== '') {
            const fileData = JSON.parse(value)
            if (fileData[0]) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    },
    getDivisionName  (divisionId) {
        if (divisionId) {
            const obj = this.$store.state.commonObj.divisionList.find(el => el.value === parseInt(divisionId))
            if (typeof obj !== 'undefined') {
              return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
            }
        } else {
            return ''
        }
    },
    getDistrictName (districtId) {
      if (districtId) {
        const obj = this.$store.state.commonObj.districtList.find(el => el.value === parseInt(districtId))
        if (typeof obj !== 'undefined') {
          return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
        }
      } else {
        return ''
      }
    },
    getUpazilaName (upazilaId) {
      const obj = this.$store.state.commonObj.upazilaList.find(el => el.value === parseInt(upazilaId))
      if (typeof obj !== 'undefined') {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      } else {
        return ''
      }
    },
    pdfExport () {
      const reportTitle = this.$i18n.locale === 'bn' ? 'ফসলের বীজ আমদানির আবেদন ফরম (বোরো হাইব্রিড)' : 'Application Form for Import of Crop Seed (Boro Hybrid)'
      ExportPdf.exportPdfDetails(reportTitle, this.badcStaticData, this, this.getOrgName(this.badcStaticData[0].org_id), this.getServiceName(this.badcStaticData[0].service_id))
    },
    getOrgName (orgId) {
      const tmpOrg = this.$store.state.commonObj.organizationProfileList.find(org => org.value === orgId)
      return this.$i18n.locale === 'en' ? tmpOrg !== undefined ? tmpOrg.text_en : '' : tmpOrg !== undefined ? tmpOrg.text_bn : ''
    },
    getServiceName (serviceId) {
      const ServiceName = this.$store.state.licenseRegistration.commonObj.serviceNamesList.find(item => item.value === serviceId)
      return this.$i18n.locale === 'en' ? ServiceName !== undefined ? ServiceName.text_en : '' : ServiceName !== undefined ? ServiceName.text_bn : ''
    },
    getFile (value) {
        if (value !== '') {
            const fileData = JSON.parse(value)
            if (fileData[0]) {
                return fileData[0]
            } else {
                return false
            }
        } else {
            return false
        }
    },
    getText (sta) {
      const reqStatusObj = this.purposeList.find(tlaw => tlaw.value === parseInt(sta))
      return reqStatusObj.text
    }
  }
}
</script>
