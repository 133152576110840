import i18n from '@/i18n'
import Store from '@/store'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (reportTitle, data, vm, orgName, serviceName) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })
    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
        pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }
    const pdfContent = [
      ]
      pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center' })
      pdfContent.push({ text: '', style: 'space' })
      const allRows = [
          [
              { text: vm.$t('org_pro.title_one'), style: 'th', alignment: 'left', bold: false },
              { text: vm.$t('org_pro.title_two'), style: 'th', alignment: 'left', bold: false },
              { text: vm.$t('org_pro.title_three'), style: 'th', alignment: 'left', bold: false },
              { text: vm.$t('org_pro.title_four'), style: 'th', alignment: 'left', bold: false }
          ],
          [
              { text: '', style: 'break' },
              { text: '', style: 'break' },
              { text: '', style: 'break' },
              { text: '', style: 'break' }
          ],
          [
              { text: vm.$t('org_pro.title_five'), style: 'th', alignment: 'left', bold: false },
              { text: vm.$t('org_pro.title_six'), style: 'th', alignment: 'left', bold: false },
              { text: vm.$t('org_pro.title_sev'), style: 'th', alignment: 'left', bold: false },
              { text: vm.$t('org_pro.title_eight'), style: 'th', alignment: 'left', bold: false }
          ]
      ]
      pdfContent.push(allRows)
      data.forEach((info, index) => {
        allRows.push([
          { },
          { text: vm.$n(info.amount), alignment: 'left', style: 'td' },
          { text: '(' + vm.$n(info.vat) + '%) = ' + vm.$n(info.amount_vat), alignment: 'left', style: 'td' },
          { text: '(' + vm.$n(info.tax) + '%) = ' + vm.$n(info.amount_tax), alignment: 'left', style: 'td' }
        ])
      })
      const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
      content: pdfContent,
      pageSize: 'A3',
      pageOrientation: 'Landcape',
      watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
      styles: {
        th: {
          fontSize: (i18n.locale === 'bn') ? 14 : 12,
          margin: [3, 3, 3, 3]
        },
        td: {
          fontSize: (i18n.locale === 'bn') ? 14 : 12,
          margin: [3, 3, 3, 3]
        },
        space: {
          margin: [10, 10, 10, 10]
        },
        break: {
          margin: [3, 3, 3, 3]
        },
        search: {
          fontSize: (i18n.locale === 'bn') ? 14 : 12,
          margin: [3, 3, 3, 3]
        },
        fertilizer: {
          margin: [5, 0, 0, 5]
        },
        header: {
          fontSize: 12,
          margin: [0, 0, 0, 4]
        },
        header2: {
          fontSize: 16,
          margin: [0, 10, 0, 20],
          bold: true
        },
        headerPort1: {
          fontSize: 10,
          margin: [0, 20, 0, 5]
        },
        headerPort: {
          fontSize: 10,
          margin: [0, 4, 0, 15]
        },
        krishi: {
          margin: [0, -5, 0, 15],
          alignment: 'center'
        },
        header3: {
          fontSize: 9,
          margin: [0, 0, 0, 0]
        },
        address: {
          fontSize: 9,
          margin: [0, -10, 0, 0]
        },
        tableSubHead: {
          margin: [0, 5, 0, 15]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).print()
  } catch (error) {
    if (error) {}
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}
export default {
  exportPdfDetails
}
