<template>
  <b-container fluid>
    <!-- <div class="col-sm-12 col-md-12 col-lg-12 text-center">
      <h4 class="">{{ $i18n.locale === 'bn' ? 'বাংলাদেশ কৃষি উন্নয়ন কর্পোরেশন' : 'Bangladesh Agricultural Development Corporation' }}</h4>
    </div> -->
    <div class="col-sm-12 col-md-12 col-lg-12 text-center">
      <h4 class="">{{ $i18n.locale === 'bn' ? 'তফসিল-২' : 'Schedule-2' }}</h4>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 text-center">
        <h5 class="">{{ $i18n.locale === 'bn' ? 'ফরম-ক' : 'Form-A' }}</h5>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12 text-center">
      <h6> {{ $i18n.locale === 'bn' ? '[বিধি ৪ (১) দ্রষ্টব্য]' : '[Rules 4(1)]' }}</h6>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12 text-center">
      <h6 class="customBorder"> {{ $i18n.locale === 'bn' ? 'নতুন নলকূপ স্থাপনের লাইসেন্সের জন্য আবেদন' : 'Application for New Tube Well Installation License' }}</h6>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 text-left">
        <h6 class="">{{ $i18n.locale === 'bn' ? 'চেয়ারম্যান' : 'Chairman' }} </h6>
        <h6 class="">{{ $i18n.locale === 'bn' ? 'উপজেলা পরিষদ' : 'Upazila Parishad' }}</h6>
        <h6 class="">{{$t('org_pro_upazilla.upazilla')}} :
          <slot v-if="isAdmin">
            {{ getColumnName($store.state.commonObj.upazilaList, officeData.upazilla_id) }}
          </slot>
          <slot v-else>
             {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, officeData.upazilla_id) }}
          </slot>
        </h6>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 pl-10 pr-10">
        <div style="height: 200px; border: 1px solid;margin-right: 20px;width:92%">
          <div class="col-sm-12 col-md-12 col-lg-12 float-right"><div style="float: right;">{{ $i18n.locale === 'bn' ? 'সুধু অফিসের কাজে ব্যবহার্য' : 'Only office work' }} </div></div>
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="row">
              <div class="col-sm-6 col-md-6 col-lg-3">{{ $i18n.locale === 'bn' ? 'ক্রমিক নং' : 'SL No' }}</div>
              <div class="col-sm-6 col-md-6 col-lg-3">.....................................</div>
              <div class="col-sm-6 col-md-6 col-lg-5">{{ $i18n.locale === 'bn' ? 'গভীর/অগভীর নলকূপ' : 'Deep/Shallow Tubewell' }}</div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-md-6 col-lg-6"></div>
              <div class="col-sm-6 col-md-6 col-lg-6">{{ $i18n.locale === 'bn' ? 'সাইজ' : 'Size' }} ()</div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-md-6 col-lg-3">{{ $i18n.locale === 'bn' ? 'আবেদন গ্রহণের তারিখ ' : 'Date of Receipt of Abadan' }}</div>
              <div class="col-sm-6 col-md-6 col-lg-8">......................................</div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-md-6 col-lg-3">{{$t('globalTrans.signature')}}</div>
              <div class="col-sm-6 col-md-6 col-lg-8">......................................</div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-md-6 col-lg-12">{{ $i18n.locale === 'bn' ? 'বিঃদ্রঃ প্রাথিত নলকূপের বিপরিতে টিক চিহ্ন প্রদান করতে হবে' : 'Note: Tick marks should be given in respect of conventional tube wells' }} </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 text-left"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder">{{ $i18n.locale === 'bn' ? '১। আবেদনকারীর তথ্য' : '1. Applicant Info' }}</h5><!----></div><!----><!---->
      <div class="col-sm-12 col-md-12 col-lg-12 pl-10">
        <div style="margin-left: 50px;margin-top: 20px;margin-bottom:20px" v-if="badcStaticData[0].a_if_the_a_3863 === 'true'">
          {{ $i18n.locale === 'bn' ? '(ক) আবেদনকারী একক ব্যক্তি হইলেঃ' : '(A) If the applicant is a single person:' }}
          <table style="margin-left: 50px;">
            <tr>
              <td>{{ $i18n.locale === 'bn' ? 'আবেদনকারীর নাম ' : 'Applicant Name' }}: {{ $i18n.locale === 'bn' ? badcStaticData[0].applicant__8 : badcStaticData[0].applicant__2827 }}</td>
            </tr>
            <tr>
              <td>{{ $i18n.locale === 'bn' ? 'পিতার নাম ' : 'Fathers Name' }} : {{ $i18n.locale === 'bn' ? badcStaticData[0].fathers_na_5861 : badcStaticData[0].fathers_na_6899 }}</td>
            </tr>
          </table>
        </div>
        <div style="margin-left: 50px;margin-top: 20px;margin-bottom:20px" v-if="badcStaticData[0].b_if_the_a_4044 === 'true'">
          {{ $i18n.locale === 'bn' ? '(খ) আবেদনকারী সমবায় সমিতি/প্রতিষ্ঠান হইলে ' : '(B) If the applicant is a Co-Operative Society/Organization' }}
          <table style="margin-left: 50px;">
            <tr>
              <td>{{ $i18n.locale === 'bn' ? 'প্রতিষ্ঠানের নাম ' : 'Company Name' }} : {{ $i18n.locale === 'bn' ? badcStaticData[0].associatio_10 : badcStaticData[0].company_na_4941 }}</td>
            </tr>
            <tr>
              <td>{{ $i18n.locale === 'bn' ? 'প্রতিষ্ঠানের রেজিস্ট্রেশন নম্বর ' : 'Company Registration No' }} : {{ badcStaticData[0].company_re_8629 }}</td>
            </tr>
          </table>
        </div>
        <div style="margin-left: 50px;margin-top: 20px;margin-bottom:20px" v-if="badcStaticData[0].c_if_the_a_3992 === 'true'">
          {{ $i18n.locale === 'bn' ? '(গ) আবেদনকারী একটি গ্রুপ হইলে' : '(C) If the applicant is a Group' }}
          <table style="margin-left: 50px;">
            <tr>
              <td>{{ $i18n.locale === 'bn' ? 'গ্রুপের নাম' : 'Group Name' }} : {{ $i18n.locale === 'bn' ? badcStaticData[0].group_name_9565 : badcStaticData[0].group_name_7644 }}</td>
            </tr>
          </table>
        </div>
        <div style="margin-left: 50px;margin-top: 20px;margin-bottom:20px" v-if="badcStaticData[0].d_represen_7429 === 'true'">
          {{ $i18n.locale === 'bn' ? '(ঘ) প্রতিনিধির তথ্যঃ' : '(D) Representative Info' }}
          <table style="margin-left: 50px;">
            <tr>
              <td>{{ $i18n.locale === 'bn' ? 'ক্ষমতাপ্রাপ্ত প্রতিনিধির নাম ' : '(D) Representative Info' }}: {{ $i18n.locale === 'bn' ? badcStaticData[0].authorized_5099 : badcStaticData[0].authorized_12 }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <br/>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder"> {{ $i18n.locale === 'bn' ? '২। আবেদনকারীর ঠিকানা' : '2. Applicant Address' }}</h5><!----></div><!----><!---->
      <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
        <table border="0" style="width: 60%">
          <tr>
            <td>{{$t('org_pro_division.division')}} :
              <slot v-if="isAdmin">
                {{ getColumnName($store.state.commonObj.divisionList, badcStaticData[0].division_22) }}
              </slot>
              <slot v-else>
                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.divisionList, badcStaticData[0].division_22) }}
              </slot>
            </td>
            <td>{{$t('org_pro_district.district')}} :
              <slot v-if="isAdmin">
                {{ getColumnName($store.state.commonObj.districtList, badcStaticData[0].district_23) }}
              </slot>
              <slot v-else>
                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_23) }}
              </slot>
            </td>
          </tr>
          <tr>
            <td>{{$t('org_pro_upazilla.upazilla')}} :
              <slot v-if="isAdmin">
                {{ getColumnName($store.state.commonObj.upazilaList, badcStaticData[0].upazilla_24) }}
              </slot>
              <slot v-else>
                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, badcStaticData[0].upazilla_24) }}
              </slot>
            </td>
            <td>{{$t('globalTrans.address')}} : {{ $i18n.locale === 'bn' ? badcStaticData[0].address_bn_25 : badcStaticData[0].address_en_4957 }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 text-left"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder">{{ $i18n.locale === 'bn' ? '৩। প্রার্থীত নলকূপের শ্রেণী' : '3. Class Of Requested tube Wells' }}</h5><!----></div><!----><!---->
      <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
        <table border="0" style="width: 60%">
          <tr>
            <td>{{ $i18n.locale === 'bn' ? 'টিউবয়েলের শ্রেণী' : 'Tubewell Class' }} :  {{ tubeDropSet(badcStaticData[0].tubewell_c_4366) }}</td>
            <td>{{ $i18n.locale === 'bn' ? 'নলকূপের ক্ষমতা' : 'Tubewell Capacity' }} : {{ getColumnNameOt(tubeCap, badcStaticData[0].tubewell_capacity_id) }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 text-left"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder"> {{ $i18n.locale === 'bn' ? '৪। নলকূপের দ্বারা সম্ভাব্য উপকৃত এলাকা' : '4. Benefited Area Information' }}</h5><!----></div><!----><!---->
      <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
        {{ badcStaticData[0]['4)_benefited_area_information'] }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 text-left"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder">{{ $i18n.locale === 'bn' ? ' ৫। নলকূপ স্থাপনের প্রস্তাবিত স্থান' : '5. Proposed location for tube well installation' }}</h5><!----></div><!----><!---->
      <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
        <table border="0" style="width: 60%">
          <tr>
            <td>{{ $i18n.locale === 'bn' ? 'মৌজা নাম' : 'Mouza Name' }} : {{ badcStaticData[0].mouza_name____47 }}</td>
            <td>{{ $i18n.locale === 'bn' ? 'জে. এল. নম্বর' : 'J. L Number' }} : {{ badcStaticData[0].j_l_number______48 }}</td>
          </tr>
          <tr>
            <td>{{ $i18n.locale === 'bn' ? 'খতিয়ান নম্বর' : 'Ledger Number' }} : {{ badcStaticData[0].ledger_and_50 }}</td>
            <td>{{ $i18n.locale === 'bn' ? 'দাগ নম্বর' : 'Plot Number' }} : {{ badcStaticData[0].spot_numbe_7889 }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 text-left"><h5 data-v-d02552b8="" class="customBorder">{{ $i18n.locale === 'bn' ? '৬। প্রস্তাবিত স্থান হইতে নিকটবর্তী গভীর/অগভীর নলকূপের দূরত্ব এবং অন্যান্য' : '6. Distance of the nearest deep / shallow tube well from the proposed site and other information' }}</h5></div>
      <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
        <table class="table table-sm table-bordered" style="font-size: 0.68rem;">
            <thead>
              <tr>
                <th>{{ this.$t('globalTrans.sl_no') }}</th>
                <th>{{ $i18n.locale === 'bn' ? 'নিকটবর্তী নলকূপের শ্রেণী' : 'Nearest Tubewell Class' }}</th>
                <th>{{ $i18n.locale === 'bn' ? 'নিকটবর্তী নলকূপের ক্ষমতা (কিউসেক)' : 'Nearest Tubewell Capacity (Cusec)' }}</th>
                <th>{{ $i18n.locale === 'bn' ? 'শক্তির উৎস' : 'Source of Energy' }}</th>
                <th>{{ $i18n.locale === 'bn' ? 'দূরত্ব (মিটার)' : 'Distance' }}</th>
              </tr>
            </thead>
            <tr v-for="(info, index) in JSON.parse(badcStaticData[0].add_more_3656)" :key="index">
              <td>{{ $n(index + 1) }}</td>
              <td>
                <span>{{ JSON.parse(badcStaticData[0].add_more_3656)[index]['Nearest Tubewell Class']}}</span>
              </td>
              <td>
                {{addMoreDataReturn(index, 'Nearest  Tubewell  Capacity (Cusec)')}}
              </td>
              <td>
                <span>{{ JSON.parse(badcStaticData[0].add_more_3656)[index]['Source of Energy']}}</span>
              </td>
              <td>
                <span>{{ JSON.parse(badcStaticData[0].add_more_3656)[index]['Distance']}} </span>
              </td>
            </tr>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 text-left"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder">{{ $i18n.locale === 'bn' ? ' ৭। লাইসেন্স প্রদানের জন্য কোন বিশেষ বিবেচনা (যদি থাকে)' : '7. Special consideration for licensing (if any)' }}</h5><!----></div><!----><!---->
      <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
        <table border="0" style="width: 60%">
          <tr>
            <td>{{ $i18n.locale === 'bn' ? 'বিশেষ বিবেচনা' : 'Special consideration' }} : {{ $i18n.locale === 'bn' ? badcStaticData[0].special_co_5272 : badcStaticData[0].special_conside_57 }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 text-left"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder">{{ $i18n.locale === 'bn' ? ' ৮। দাখিলকৃত প্রয়োজনীয় কাগজপত্রের তালিকা' : '8. List of required documents to be submitted' }}</h5><!----></div><!----><!---->
      <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
        <table border="0" style="width: 60%">
          <tr v-if="getIsFile(badcStaticData[0]._2822)">
            <td>{{ $n(1) }}.</td>
            <td>
              <div type="button" class="btn btn-sm btn-success">
                <a target="_blank" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[0]._2822)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
              </div>
            </td>
          </tr>
          <tr v-if="getIsFile(badcStaticData[0]._4571)">
            <td>{{ $n(2) }}.</td>
            <td>
              <div type="button" class="btn btn-sm btn-success">
                <a target="_blank" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[0]._4571)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
              </div>
            </td>
          </tr>
          <tr v-if="getIsFile(badcStaticData[0]._3258)">
            <td>{{ $n(3) }}.</td>
            <td>
              <div type="button" class="btn btn-sm btn-success">
                <a target="_blank" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[0]._3258)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
              </div>
            </td>
          </tr>
          <tr v-if="getIsFile(badcStaticData[0]._2339)">
            <td>{{ $n(4) }}.</td>
            <td>
              <div type="button" class="btn btn-sm btn-success">
                <a target="_blank" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[0]._2339)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
              </div>
            </td>
          </tr>
          <tr v-if="getIsFile(badcStaticData[0]._5408)">
            <td>{{ $n(5) }}.</td>
            <td>
               <div type="button" class="btn btn-sm btn-success">
                <a target="_blank" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[0]._5408)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row">
      <br/>
      <br/>
      <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
        <table border="0" style="width: 98%">
          <tr>
            <td align="left">
              <div style="width: 120px; text-align:center;">
                {{ badcStaticData[0].created_at | dateFormat }}<br/>
                {{ $i18n.locale === 'bn' ? 'আবেদনের তারিখ' : 'Application Date' }}
              </div>
            </td>
            <td align="right" style="padding-right: 30px">
              <br/>
              {{ $i18n.locale === 'bn' ? 'আবেদনকারীর নাম ও স্বাক্ষর' : "Applicant's name and Signature" }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </b-container>
</template>
<script>
import { licenseRegistrationServiceBaseUrl } from '@/config/api_config'

export default {
  props: ['badcStaticData', 'officeData', 'isAdmin'],
  data () {
    return {
      loading: false,
      badcNew: [],
      lrcpanBaseUrl: licenseRegistrationServiceBaseUrl,
      tubeDrop:
      [
        { text: this.$i18n.locale === 'bn' ? 'গভীর নলকূপ' : 'Deep Tubewell', value: 0 },
        { text: this.$i18n.locale === 'bn' ? 'অগভীর নলকূপ' : 'Shallow Tubewell', value: 1 }
      ],
      tubeCap:
      [
        { text: this.$i18n.locale === 'bn' ? '২.০ কিউসেক' : '2.0 Cusec', value: 0 },
        { text: this.$i18n.locale === 'bn' ? '১.৫ কিউসেক' : '1.5 Cusec', value: 1 },
        { text: this.$i18n.locale === 'bn' ? '১.০ কিউসেক' : '1.0 Cusec', value: 2 },
        { text: this.$i18n.locale === 'bn' ? '০.৫ কিউসেক' : '0.5 Cusec', value: 3 },
        { text: this.$i18n.locale === 'bn' ? 'নাই' : 'N/A', value: 4 }
      ]
    }
  },
  created () {
  },
  methods: {
    tubeDropSet (fieldVals) {
      const dataVal = []
      const flVal = JSON.parse(fieldVals)
      if (flVal) {
        flVal.forEach((item, key) => {
          const dataFind = this.tubeDrop.find(dt => dt.value === parseInt(item))
          if (this.$i18n.locale === 'bn') {
            dataVal.push(dataFind.text)
          } else {
            dataVal.push(dataFind.text)
          }
        })
      }
      return dataVal.toString()
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    getColumnNameOt (list, groupId) {
      const obj = list.find(item => parseInt(item.value) === parseInt(groupId))
      if (typeof obj !== 'undefined') {
        return obj.text
      } else {
          return ''
      }
    },
    addMoreDataReturn (index, value) {
      let newDD = ''
      const allJsonData = JSON.parse(this.badcStaticData[0].add_more_3656)[index]
      Object.keys(allJsonData).forEach(key => {
        if (key === 'Nearest  Tubewell  Capacity (Cusec)') {
          const checkData = allJsonData['Nearest  Tubewell  Capacity (Cusec)']
          newDD = checkData
        }
      })
      return newDD
    },
    getIsFile (value) {
        if (value !== '') {
            const fileData = JSON.parse(value)
            if (fileData[0]) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    },
    getFile (value) {
        if (value !== '') {
            const fileData = JSON.parse(value)
            if (fileData[0]) {
                return fileData[0]
            } else {
                return false
            }
        } else {
            return false
        }
    }
  }
}
</script>
