<template>
  <b-container fluid>
    <div class="col-sm-12 col-md-12 col-lg-12 text-center">
      <h4 class="">{{ $i18n.locale === 'bn' ? 'ফসলের বীজ রপ্তানির আবেদন' : 'Bangladesh Agricultural Development Corporation' }}</h4>
    </div>
    <hr/>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h6 class="">{{ $i18n.locale === 'bn' ? 'বরাবর' : 'Form-A' }}</h6>
        <h6 class="">{{ $i18n.locale === 'bn' ? 'সচিব,জাতীয় বীজ বোর্ড' : 'Form-A' }}</h6>
        <h6 class="">{{ $i18n.locale === 'bn' ? 'ও মহাপরিচালক, বীজ অনুবিভাগ' : 'Form-A' }}</h6>
        <h6 class="">{{ $i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়।' : 'Form-A' }}</h6>
        <h6 class="">{{ $i18n.locale === 'bn' ? 'বিষয় : ফসলের বীজ রপ্তানির আবেদন।' : 'Form-A' }}</h6>
        <h6 class="">{{ $i18n.locale === 'bn' ? 'জনাব,' : 'Form-A' }}</h6>
        <h6 class="">{{ $i18n.locale === 'bn' ? 'সবিনয় নিবেদন এই যে, আমি নিম্নস্বাক্ষরকারী উপর্যুক্ত বিষয়ে প্রয়োজনীয় তথ্যাদি আপনার সদয় বিবেচনার জন্য উপস্থাপন করলাম।' : 'Form-A' }}</h6>
      </div>
    </div>
    <br/>
    <br/>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 text-left">
        <table style="width: 80%">
          <tr>
            <td style="width: 25%">{{ $i18n.locale === 'bn' ? 'আবেদনকারীর নাম' : 'Form-A' }}</td>
            <td style="width: 5%">:</td>
            <td>{{ $i18n.locale === 'bn' ? badcStaticData[0].applicant_nameb_6 : badcStaticData[0].applicant_namee_5 }}</td>
          </tr>
          <tr>
            <td style="width: 25%">{{ $i18n.locale === 'bn' ? 'পদবী' : 'Form-A' }}</td>
            <td style="width: 5%">:</td>
            <td>{{ $i18n.locale === 'bn' ? badcStaticData[0].designatio__1054 : badcStaticData[0].designatio__1370 }}</td>
          </tr>
          <tr>
            <td style="width: 25%">{{ $i18n.locale === 'bn' ? 'প্রতিষ্ঠানের নাম' : 'Form-A' }}</td>
            <td style="width: 5%">:</td>
            <td>{{ $i18n.locale === 'bn' ? badcStaticData[0].organizati_7826 : badcStaticData[0].organizati_1680 }}</td>
          </tr>
          <tr>
            <td style="width: 25%">{{$t('org_pro_division.division')}}</td>
            <td style="width: 5%">:</td>
            <td>
              <slot v-if="isAdmin">
                {{ getColumnName($store.state.commonObj.divisionList, badcStaticData[0].division_i_7154) }}
              </slot>
              <slot v-else>
                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.divisionList, badcStaticData[0].division_i_7154) }}
              </slot>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">{{$t('org_pro_district.district')}}</td>
            <td style="width: 5%">:</td>
            <td>
              <slot v-if="isAdmin">
                {{ getColumnName($store.state.commonObj.districtList, badcStaticData[0].district_id_5012) }}
              </slot>
              <slot v-else>
                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_id_5012) }}
              </slot>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">{{$t('org_pro_upazilla.upazilla')}}</td>
            <td style="width: 5%">:</td>
            <td>
              <slot v-if="isAdmin">
                {{ getColumnName($store.state.commonObj.upazilaList, badcStaticData[0].upazilla_id_3716) }}
              </slot>
              <slot v-else>
                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, badcStaticData[0].upazilla_id_3716) }}
              </slot>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">{{$t('org_pro.addresss')}}</td>
            <td style="width: 5%">:</td>
            <td>
              {{ $i18n.locale === 'bn' ? badcStaticData[0].address_bn_6799 : badcStaticData[0].address_en_6360 }}
            </td>
          </tr>
          <tr>
            <td style="width: 25%">{{$t('org_pro.com_reg')}}</td>
            <td style="width: 5%">:</td>
            <td>
              {{ $n(badcStaticData[0].seed_deale_4) }}
            </td>
          </tr>
          <tr>
            <td style="width: 25%">{{$t('org_pro.mobile_no')}}</td>
            <td style="width: 5%">:</td>
            <td>
              {{ $n(badcStaticData[0].mobile_no__7) }}
            </td>
          </tr>
          <tr>
            <td style="width: 25%">{{$t('org_pro.email')}}</td>
            <td style="width: 5%">:</td>
            <td>
              {{ badcStaticData[0].email____8 }}
            </td>
          </tr>
          <tr>
            <td style="width: 25%">{{$t('org_pro.seed_export')}}</td>
            <td style="width: 5%">:</td>
            <td>
                {{ getText(badcStaticData[0].the_purpos_9) }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <br/>
    <div class="col-sm-12 col-md-12 col-lg-12 text-center">
      <h4 class="">{{ $t('org_pro.seed_ex_desc') }}</h4>
      <hr/>
    </div>
    <br/>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 text-left">
        <b-table-simple striped bordered small class="mt-2">
            <b-tr>
                <b-th> {{ $t('globalTrans.sl_no') }}</b-th>
                <b-th> {{ $t('org_pro.crop_name') }}</b-th>
                <b-th> {{ $t('org_pro.variety_name') }}</b-th>
                <b-th> {{ $t('org_pro.variety_reg') }}</b-th>
                <b-th> {{ $t('org_pro.export_country') }}</b-th>
                <b-th> {{ $t('org_pro.export_company') }}</b-th>
                <b-th> {{ $t('org_pro.seed_ex_quant') }}</b-th>
                <b-th> {{ $t('globalTrans.remarks') }}</b-th>
            </b-tr>
            <b-tr v-for="(dataIt, index) in JSON.parse(badcStaticData[0].add_more_7791)" :key="index">
              <b-td>{{ $n(index + 1) }}</b-td>
              <b-td>{{ dataIt['Crop Name '] }}</b-td>
              <b-td>{{ dataIt['Variety Name'] }}</b-td>
              <b-td>{{ $n(dataIt['Verity Reg. No']) }}</b-td>
              <b-td>{{ dataIt['Exported Country Name'] }}</b-td>
              <b-td>{{ dataIt['Exported Company Name'] }}</b-td>
              <b-td>{{ $n(dataIt['Seed Export Quantity (Metric Ton)']) }}</b-td>
              <b-td>{{ dataIt['Remarks'] }}</b-td>
            </b-tr>
        </b-table-simple>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h6 class="">{{ $t('org_pro.low_desc') }}</h6>
      </div>
    </div>
  </b-container>
</template>
<script>
import { licenseRegistrationServiceBaseUrl } from '@/config/api_config'

export default {
  props: ['badcStaticData', 'officeData', 'isAdmin'],
  data () {
    return {
      loading: false,
      badcNew: [],
      lrcpanBaseUrl: licenseRegistrationServiceBaseUrl,
      tubeDrop:
      [
        { text: this.$i18n.locale === 'bn' ? 'গভীর নলকূপ' : 'Deep Tubewell', value: 0 },
        { text: this.$i18n.locale === 'bn' ? 'অগভীর নলকূপ' : 'Shallow Tubewell', value: 1 }
      ],
      purposeList:
      [
        { value: 0, text: this.$i18n.locale === 'bn' ? 'গবেষণা' : 'Deep Tubewell' },
        { value: 1, text: this.$i18n.locale === 'bn' ? 'বাণিজ্যিক' : 'Deep Tubewell' },
        { value: 2, text: this.$i18n.locale === 'bn' ? 'উপযোগিতা যাচাই' : 'Deep Tubewell' }
      ],
      tubeCap:
      [
        { text: this.$i18n.locale === 'bn' ? '২.০ কিউসেক' : '2.0 Cusec', value: 0 },
        { text: this.$i18n.locale === 'bn' ? '১.৫ কিউসেক' : '1.5 Cusec', value: 1 },
        { text: this.$i18n.locale === 'bn' ? '১.০ কিউসেক' : '1.0 Cusec', value: 2 },
        { text: this.$i18n.locale === 'bn' ? '০.৫ কিউসেক' : '0.5 Cusec', value: 3 },
        { text: this.$i18n.locale === 'bn' ? 'নাই' : 'N/A', value: 4 }
      ]
    }
  },
  created () {
  },
  computed: {
  },
  methods: {
    tubeDropSet (fieldVals) {
      const dataVal = []
      const flVal = JSON.parse(fieldVals)
      if (flVal) {
        flVal.forEach((item, key) => {
          const dataFind = this.tubeDrop.find(dt => dt.value === parseInt(item))
          if (this.$i18n.locale === 'bn') {
            dataVal.push(dataFind.text)
          } else {
            dataVal.push(dataFind.text)
          }
        })
      }
      return dataVal.toString()
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    getColumnNameOt (list, groupId) {
      const obj = list.find(item => parseInt(item.value) === parseInt(groupId))
      if (typeof obj !== 'undefined') {
        return obj.text
      } else {
          return ''
      }
    },
    addMoreDataReturn (index, value) {
      let newDD = ''
      const allJsonData = JSON.parse(this.badcStaticData[0].add_more_3656)[index]
      Object.keys(allJsonData).forEach(key => {
        if (key === 'Nearest  Tubewell  Capacity (Cusec)') {
          const checkData = allJsonData['Nearest  Tubewell  Capacity (Cusec)']
          newDD = checkData
        }
      })
      return newDD
    },
    getIsFile (value) {
        if (value !== '') {
            const fileData = JSON.parse(value)
            if (fileData[0]) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    },
    getFile (value) {
        if (value !== '') {
            const fileData = JSON.parse(value)
            if (fileData[0]) {
                return fileData[0]
            } else {
                return false
            }
        } else {
            return false
        }
    },
    getText (sta) {
      const reqStatusObj = this.purposeList.find(tlaw => tlaw.value === parseInt(sta))
      return reqStatusObj.text
    }
  }
}
</script>
